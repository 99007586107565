import React, { useContext, useState, useEffect } from 'react';
import Context from '../../context/context';
import * as XLSX from 'xlsx';
import { ASPECT_TYPES } from '../../constants';
import { FaDownload } from 'react-icons/fa';

const DownloadAspectsDataButton = () => {
  const { filteredReviews, selectedCategory, updateSelectedCategory } =
    useContext(Context);
  const [aspectsData, setAspectsData] = useState([]);
  const [radarData, setRadarData] = useState([]);

  useEffect(() => {
    if (filteredReviews.length > 0) {
      setAspectsData(computeAspectsData());
      if (!selectedCategory) updateSelectedCategory(null);
      setRadarData(computeRadarData());
    }
  }, [filteredReviews, selectedCategory]);

  const computeAspectsData = () => {
    const aspectsDataMap = {};
    ASPECT_TYPES.forEach((aspect) => {
      aspectsDataMap[aspect] = 0;
    });

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        aspectsDataMap[aspect.aspect] += 1;
      });
    });

    return Object.keys(aspectsDataMap).map((aspect) => ({
      Aspect: aspect,
      'Times mentioned': aspectsDataMap[aspect],
    }));
  };

  const computeRadarData = () => {
    const radarDataMap = {};
    ASPECT_TYPES.forEach((aspect) => {
      radarDataMap[aspect] = { positive: 0, negative: 0 };
    });

    filteredReviews.forEach((review) => {
      if (review.establishment_type === selectedCategory) {
        review.aspectsentiment_set.forEach((aspect) => {
          if (aspect.label === 'Positive') {
            radarDataMap[aspect.aspect].positive += 1;
          } else if (aspect.label === 'Negative') {
            radarDataMap[aspect.aspect].negative += 1;
          }
        });
      }
    });

    return Object.keys(radarDataMap).map((aspect) => {
      const positives = radarDataMap[aspect].positive;
      const negatives = radarDataMap[aspect].negative;
      const value =
        positives + negatives === 0
          ? 0
          : Math.round((positives / (positives + negatives)) * 100); // Calculate percentage

      return {
        Aspect: aspect,
        Value: value,
        'Positives sentiments': positives,
        'Negatives sentiments': negatives,
      };
    });
  };

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();

    const aspectSheet = XLSX.utils.json_to_sheet(aspectsData);
    const radarSheet = XLSX.utils.json_to_sheet(radarData);

    XLSX.utils.book_append_sheet(workbook, aspectSheet, 'Aspects Data');
    XLSX.utils.book_append_sheet(workbook, radarSheet, 'Radar Data');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'aspects_data.xlsx';
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleDownloadExcel}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadAspectsDataButton;
