import React, { useContext, useState } from "react";
import Context from '../../context/context';
import { Dropdown } from 'antd';


import "./year-selection.css";

const YearSelection = ({setCurrentYear}) => {
  const {
    selectedYear,
    updateSelectedYear,
  } = useContext(Context);

  const currentYear = new Date().getFullYear()
  const lastFiveYears = Array.from({length: 5}, (_, i) => String(currentYear - i))
  lastFiveYears.push('All')

  const items = lastFiveYears.map((year) => ({ key: year, label: year }))

  return (
    <div className="yearSelect">
      <span className="yearLabel">Year: </span>
      <Dropdown
        className="button-main"
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [selectedYear],
          onClick: ({ key }) => {
            updateSelectedYear(key)
            setCurrentYear(key)
          }
        }}
      >
        <span>
          {selectedYear}
        </span>
      </Dropdown>
    </div>
  );
}

export default YearSelection
