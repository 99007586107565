import React from 'react';
import PropTypes from 'prop-types';
import './chart-tooltip.css';

const ChartTooltip = (props) => {
  const {
    top, bottom, left, right, title, value, visible
  } = props;

  return (
    (value !== '' && value !== undefined) &&
    <div className='chart-tooltip' style={{
      top: top,
      left: left,
    }}>
        <h3 className='chart-tooltip-title'>{title}</h3>
        <p className='chart-tooltip-value'>{value}</p>
    </div>
  )
};

ChartTooltip.propTypes = {};

ChartTooltip.defaultProps = {};

export default ChartTooltip;
