import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';
import { rewind } from '@turf/rewind';
import { Row, Col, Modal, Button } from 'antd';
import { Radio } from 'antd';
import * as d3 from 'd3';
import axios from 'axios';
import Map from '../../components/map/map';
import ChartCard from '../../components/chart-card/chart-card';
import Section from '../../components/section/section';
import MultyBarChart from '../../components/multy-bar-chart/multy-bar-chart';
import Context from '../../context/context';
import { ASPECT_TYPES, GEOJSON } from '../../constants';
import HorizontalBarChart from '../../components/bar-chart/horizontal-bar-chart'; // Importing the HorizontalBarChart component
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';
import EstablishmentTypeToggle from '../../components/establishment-type-toggle/establishment-type-toggle'

import './regions.css';
import { scrollToTop } from '../../utils/helpers';

const Regions = (props) => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateSelectedRegion,
  } = useContext(Context);

  const geojsonData = useLoaderData();

  const [regionData, setRegionData] = useState(computeRegionData());
  const [mapDataType, setMapDataType] = useState('visits');
  const [establishmentSentimentData, setEstablishmentSentimentData] =
    useState();
  const [aspectSentimentData, setAspectSentimentData] = useState();
  const [sentimentDistribution, setSentimentDistribution] = useState({});
  const [keywordData, setKeywordData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dataRef = useRef();
  dataRef.current = selectedRegion;

  function computeSentimenDistibution() {
    return filteredReviews.reduce(
      (acc, review) => {
        review.aspectsentiment_set.forEach((aspect) => {
          acc[aspect.label] += 1;
        });
        return acc;
      },
      {
        Positive: 0,
        Neutral: 0,
        Negative: 0,
      }
    );
  }

  function computeKeywordFrequency() {
    const keywordFrequency = {};

    filteredReviews.forEach((review) => {
      review.keyword_set.forEach((keywordObj) => {
        const keywords = keywordObj.keywords.split(', ');
        keywords.forEach((keyword) => {
          // Remove unwanted characters including single quotes
          const cleanKeyword = keyword.replace(/[\[\]',]/g, '').trim();
          if (keywordFrequency[cleanKeyword]) {
            keywordFrequency[cleanKeyword] += 1;
          } else {
            keywordFrequency[cleanKeyword] = 1;
          }
        });
      });
    });

    // Sort the keyword frequency data in descending order
    return Object.entries(keywordFrequency)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0,50);
  }

  function mapRegionClick(e, d) {
    if (dataRef.current.includes(d.properties.shapeName)) {
      updateSelectedRegion(
        dataRef.current.filter((region) => region !== d.properties.shapeName)
      );
    } else {
      updateSelectedRegion([...dataRef.current, d.properties.shapeName]);
    }
  }

  function computeRegionData() {
    const visitsData = filteredReviews.reduce((acc, review) => {
      let region = review.district;
      if (acc[region]) {
        acc[region] += 1;
      } else {
        acc[region] = 1;
      }
      return acc;
    }, {});
    const sentimentData = filteredReviews.reduce((acc, review) => {
      let region = review.district;
      if (!acc[region]) {
        acc[region] = {
          negative: 0,
          positive: 0,
        };
      }
      review.aspectsentiment_set.forEach((aspect) => {
        if (aspect.label === 'Negative') {
          acc[region].negative += 1;
        } else {
          acc[region].positive += 1;
        }
      });
      return acc;
    }, {});
    return {
      visits: visitsData,
      sentiment: sentimentData,
    };
  }

  function getVisitValueFunction(item) {
    return item ? item : 0;
  }

  function getSentimentValueFunction(item) {
    if (!item) return 0;
    const sum = item.positive + item.negative;
    return sum ? (item.positive / sum - item.negative / sum) * 100 : 0;
  }

  function getVisitColors(data) {
    const valuesArray = Object.values(data).sort((a, b) => a - b);
    const step = Math.floor(
      (valuesArray[valuesArray.length - 1] - valuesArray[0]) / 10
    );
    const trasholdArray = [valuesArray[0]];
    for (let i = 1; i < 5; i++) {
      trasholdArray.push(trasholdArray[i - 1] + step * i);
    }
    return {
      trashold: trasholdArray,
      range: d3.schemeBlues[7],
    };
  }

  function getSentimentColors() {
    return {
      trashold: [-100, -75, -50, -25, -1, 0, 1, 25, 50, 75, 100],
      range: d3.schemeOranges[5]
        .reverse()
        .concat(['#ffffff'], d3.schemeGreens[5]),
    };
  }

  function getVisitTooltipText(item) {
    if (!item) return 0;
    return item;
  }

  function getSentimentTooltipText(item) {
    if (!item) return 0;
    return `Positive: ${item.positive}, Negative: ${item.negative}`;
  }

  function getEstablishmentSentimentData() {
    const EstablishmentMap = {};

    filteredReviews.forEach((review) => {
      const date = new Date(review.date);
      const year = date.getFullYear();
      if (!EstablishmentMap[review.establishment_type]) {
        EstablishmentMap[review.establishment_type] = {
          negative: 0,
          positive: 0,
        };
      }

      review.aspectsentiment_set.forEach((aspect) => {
        if (selectedSentiment === 'Positive' && aspect.label === 'Positive') {
          EstablishmentMap[review.establishment_type].positive += 1;
        } else if (
          selectedSentiment === 'Negative' &&
          aspect.label === 'Negative'
        ) {
          EstablishmentMap[review.establishment_type].negative += 1;
        } else if (!selectedSentiment) {
          // No sentiment filter applied; include all data
          if (aspect.label === 'Positive') {
            EstablishmentMap[review.establishment_type].positive += 1;
          } else if (aspect.label === 'Negative') {
            EstablishmentMap[review.establishment_type].negative += 1;
          }
        }
      });
    });

    let res = Object.entries(EstablishmentMap).map(
      ([establishment, establishmentData]) => {
        let res = {};
        res['group'] = establishment;
        Object.entries(establishmentData).map(([key, value]) => {
          res[key] = value;
        });
        return res;
      }
    );
    res.columns = ['group', 'positive', 'negative'];
    return res;
  }

  function getAspectSentimentData() {
    const AspectMap = ASPECT_TYPES.reduce((acc, aspect) => {
      acc[aspect] = {
        positive: 0,
        negative: 0,
      };
      return acc;
    }, {});

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        if (selectedSentiment === 'Positive' && aspect.label === 'Positive') {
          AspectMap[aspect.aspect].positive += 1;
        } else if (
          selectedSentiment === 'Negative' &&
          aspect.label === 'Negative'
        ) {
          AspectMap[aspect.aspect].negative += 1;
        } else if (!selectedSentiment) {
          // No sentiment filter applied; include all data
          if (aspect.label === 'Positive') {
            AspectMap[aspect.aspect].positive += 1;
          } else if (aspect.label === 'Negative') {
            AspectMap[aspect.aspect].negative += 1;
          }
        }
      });
    });

    let res = Object.entries(AspectMap).map(([aspect, aspectData]) => {
      let res = {};
      res['group'] = aspect;
      Object.entries(aspectData).map(([key, value]) => {
        res[key] = value;
      });
      return res;
    });
    res.columns = ['group', 'positive', 'negative'];
    return res;
  }

  useEffect(() => {
    setEstablishmentSentimentData(getEstablishmentSentimentData());
    setAspectSentimentData(getAspectSentimentData());
    setRegionData(computeRegionData());
    setSentimentDistribution(computeSentimenDistibution());
    setKeywordData(computeKeywordFrequency());
  }, [filteredReviews, selectedSentiment]);

  useEffect(() => {
    scrollToTop()
  }, [])

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Regions Overview" />
        </Col>
      </Row>
      <Row style={{ marginTop: '12px', marginBottom: '2px' }}>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <Row justify={{lg: 'space-between', sm: 'start'}} style={{marginBottom: '10px'}}>
        <EstablishmentTypeToggle />
        <Button onClick={showModal}>
          See Top Keywords
        </Button>
      </Row>
      <Row>
        <Radio.Group
          value={mapDataType}
          onChange={(e) => setMapDataType(e.target.value)}
        >
          <Radio.Button value="sentiment">Sentiment</Radio.Button>
          <Radio.Button value="visits">Reviewers</Radio.Button>
        </Radio.Group>
      </Row>
      <Row className='regions-charts-container'>
        <Col className='regions-map'>
          <Map
            id="regions"
            data={regionData[mapDataType]}
            click={(e, d) => {
              mapRegionClick(e, d);
            }}
            geodata={geojsonData}
            getValueFunction={
              mapDataType === 'visits'
                ? getVisitValueFunction
                : getSentimentValueFunction
            }
            getColorsFunction={
              mapDataType === 'visits' ? getVisitColors : getSentimentColors
            }
            getTooltipTextFunction={
              mapDataType === 'visits'
                ? getVisitTooltipText
                : getSentimentTooltipText
            }
            fileName={`region-distribution-${mapDataType}`}
            columns={
              mapDataType === 'visits'
                ? { key: 'Number of Reviews' }
                : {
                    positive: 'Positive Sentiments',
                    negative: 'Negative Sentiments',
                  }
            }
            filters={appliedFilters}
            title={`${
              mapDataType === 'visits'
                ? 'Region visits'
                : 'Sentiments distribution by regions'
            }`}
          />
        </Col>
        <Col className='regions-charts'>
          <Section
            title={
              <span>
                Distribution of Positive and Negative Comments by Establishment
                Type
              </span>
            }
          >
            <ChartCard className="chart-card aspect-types-chart">
              <MultyBarChart
                id="establishment-sentiment"
                data={establishmentSentimentData}
                color={['#6FCF97', '#EB5757']}
                colorScale={d3
                  .scaleOrdinal()
                  .domain(['Positive', 'Negative'])
                  .range(['#6FCF97', '#EB5757'])}
                columns={{
                  group: 'Type',
                  positive: 'Positive',
                  negative: 'Negative',
                }}
                fileName="establishments-sentiments"
                filters={appliedFilters}
                title={'Establishment Types'}
              />
            </ChartCard>
          </Section>
          <Section
            title={
              <span>
                Distribution of Positive and Negative Comments by Aspect Type
              </span>
            }
          >
            <ChartCard className="chart-card aspect-types-chart">
            <MultyBarChart
              id="aspect-sentiment"
              data={aspectSentimentData}
              xTickTotated
              color={['#6FCF97', '#EB5757']}
              colorScale={d3
                .scaleOrdinal()
                .domain(['Positive', 'Negative'])
                .range(['#6FCF97', '#EB5757'])}
              columns={{
                group: 'Aspect',
                positive: 'Positive',
                negative: 'Negative',
              }}
              fileName="aspects-sentiments"
              filters={appliedFilters}
              title={'Aspect Types'}
            />
            </ChartCard>
          </Section>
        </Col>
      </Row>
      <Modal open={isModalOpen} onCancel={handleOk}>
        <Col span={24}>
          <Section title="Top Keywords">
            <ChartCard className="chart-card keyword-frequency-chart">
              {keywordData && keywordData.length > 0 ? (
                <HorizontalBarChart
                  id="keyword-frequency"
                  data={keywordData}
                  color="#6FCF97"
                  height={keywordData.length * 25} // Adjust height dynamically based on data length
                />
              ) : (
                <p>No keyword data available.</p>
              )}
            </ChartCard>
          </Section>
        </Col>
      </Modal>
    </>
  );
};

Regions.propTypes = {};

Regions.defaultProps = {};

export default Regions;

export async function regionsLoader() {
  const geojsonData = await axios.get(GEOJSON);
  geojsonData.data.features = geojsonData.data.features.map((f) => {
    return rewind(f, { reverse: true });
  });
  return geojsonData.data;
}
