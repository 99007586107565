import React from 'react';
import cx from 'classnames';
import { Layout } from 'antd';

import './shared-footer.css'

const SharedFooter = ({className}) => {

  const { Footer } = Layout;

  const partners = [
    {
      image: require('./../../assets/visitmalawilogo.png'),
      url: 'https://visitmalawi.mw',
      alt: 'Visit Malawi',
    },
    {
      image: require('./../../assets/Coat_of_arms_of_Malawi.svg.png'),
      url: 'https://www.malawi.gov.mw',
      alt: 'Government of Malawi',
    },
    {
      image: require('./../../assets/sdgailab_logo.png'),
      url: 'https://sdgailab.org',
      alt: 'SDG AI Lab',
    },
    {
      image: require('./../../assets/undp_logo.png'),
      url: 'https://www.undp.org/malawi',
      alt: 'UNDP Malawi',
    },
  ];

  return (
    <Footer className={cx("footer footer-container", className)}>
      <div className='footer-logo-container'>
        <div className='footer-logo'>
          <img
            src={require('./../../assets/mlondola-footer.png')}
            alt="footer logo"
          />
          <span style={{ color: '#fdc866', marginTop: '8px', marginLeft: '5px' }}> © 2025 </span>
        </div>
        <div className="footer-links">
          <h3 className='quick-links'>Quick Links: </h3>
          <a href="/about">About </a>|
          <a href="/faqs">FAQs </a>|
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
      <div className='footer-partners'>
        <h2 style={{margin: 0, color: 'white'}}>Our Partners:</h2>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a
              key={index}
              href={partner.url}
              target="_blank"
              rel="noopener noreferrer"
              className="partner-link"
              title={partner.alt}
            >
              <img
                className="home-sponsors-image"
                src={partner.image}
                alt={partner.alt}
              />
            </a>
          ))}
        </div>
      </div>

    </Footer>

  );
}

export default SharedFooter;
