import React, { useState, useEffect } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaSearch,
  FaArrowUp,
} from 'react-icons/fa';
import './faqs.css';
import { scrollToTop } from '../../utils/helpers';

const faqs = {
  '📌 General Questions': [
    {
      question: 'What is Mlondola?',
      answer:
        'Mlondola is a platform that provides real-time data insights for Malawi’s tourism sector, analyzing tourist sentiments, popular destinations, and visitor preferences​.',
    },
    {
      question: 'What are the key features of Mlondola?',
      answer:
        '- Real-Time Tourism Insights – Live data on visitor experiences.\n- Sentiment Analysis – Detects positive or negative feedback.\n- Geospatial Analysis – Maps tourist movements and trends.\n- Predictive Analytics – Forecasts future tourism patterns.',
    },
    {
      question: 'How does Mlondola analyze visitor sentiments?',
      answer:
        'Mlondola uses Natural Language Processing (NLP) to detect keywords and classify positive or negative sentiments in reviews​.',
    },
  ],
  '📌 Using the Platform': [
    {
      question: 'What are the main pages on Mlondola?',
      answer:
        'The platform consists of five main analytical pages: \n - Intro – Overview of tourism statistics. \n - Categories – Sentiment and review analysis. \n - Regions – Tourism data by location. \n - Aspects – Evaluates comfort, cleanliness, safety, etc. \n - Prices – Tracks pricing trends and affordability.',
    },
    {
      question: 'How do I use the filtering options?',
      answer:
        'You can filter data by:\n✅ Date Range – Select a time period.\n✅ Category – Accommodation, Attractions, Restaurants.\n✅ Sentiment – Positive or Negative.\n✅ Region – Choose a specific location.',
    },
    {
      question: 'Can I download charts and data from Mlondola?',
      answer:
        'Yes, you can:\n📥 Download raw data as an Excel file (.XLSX).\n🖼 Save charts as images (.PNG) for reports and presentations.',
    },
  ],
  '📌 Data & Insights': [
    {
      question: 'How does Mlondola rank top establishments?',
      answer:
        'Establishments are ranked using a Bayesian Probability formula, which balances review scores and total review count for fair rankings.',
    },
    {
      question:
        'Why do positive and negative reviews not add up to the total reviews?',
      answer:
        'A single review can mention multiple aspects (e.g., cleanliness, service, location), so it can contribute both positive and negative sentiment scores​.',
    },
    {
      question: 'How does Mlondola visualize tourism trends by region?',
      answer:
        'Mlondola uses a color-coded map of Malawi, where: \n 🟢 Green regions indicate higher positive sentiment. \n 🔵 Blue regions indicate higher review volume​.',
    },
    {
      question: 'What kind of seasonal trends can I see?',
      answer:
        'You can analyze visitor trends by season (Winter, Spring, Summer, Autumn) to see peak and low tourism periods​.',
    },
    {
      question: 'What insights can I get about visitor demographics?',
      answer:
        'You can see: \n - Visitor gender distribution (Male vs. Female). \n - Top visitor countries via a world map visualization​.',
    },
  ],
  '📌 Technical Issues & Support': [
    {
      question: 'How often is Mlondola’s data updated?',
      answer:
        'Mlondola updates data in real-time as new reviews and trends emerge.',
    },
    {
      question: 'What should I do if my filters are not working?',
      answer:
        'Ensure you have selected at least one category. If the issue persists, refresh the page or clear the filters using the “Clear” button.',
    },
    {
      question: 'Why can’t I see all data without logging in?',
      answer:
        'Some data and insights are restricted to logged-in users for security and to prevent data scraping.',
    },
    {
      question: 'How do I contact support?',
      answer:
        'For any issues, contact: \n 📧 Mlondola Support Team: support@mlondola.com.',
    },
  ],
};

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showBackToTop, setShowBackToTop] = useState(false);

  const toggleFAQ = (category, index) => {
    setOpenIndex(
      openIndex && openIndex.category === category && openIndex.index === index
        ? null
        : { category, index }
    );
  };

  const filteredFaqs = Object.entries(faqs)
    .map(([category, questions]) => ({
      category,
      questions: questions.filter((faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    }))
    .filter(({ questions }) => questions.length > 0);

  const handleScroll = () => {
    setShowBackToTop(window.scrollY > 200);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="mlondola-faq-page">
      <div className="container faq-container">
        <h1 className="faq-title">Frequently Asked Questions</h1>
        <p className="faq-subtitle">
          Find answers to common questions about the Mlondola platform.
        </p>

        {/* 🔎 Search Bar */}
        <div className="faq-search">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search questions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Show FAQs or "No results" message */}
        {filteredFaqs.length > 0 ? (
          filteredFaqs.map(({ category, questions }) => (
            <div key={category} className="faq-category">
              <h2>{category}</h2>
              {questions.map((faq, index) => (
                <div
                  key={index}
                  className="faq-item"
                  onClick={() => toggleFAQ(category, index)}
                >
                  <div className="faq-question">
                    <h3>{faq.question}</h3>
                    {openIndex &&
                    openIndex.category === category &&
                    openIndex.index === index ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>
                  {openIndex &&
                    openIndex.category === category &&
                    openIndex.index === index &&
                    // <p className="faq-answer">{faq.answer}</p>
                    faq.answer.split('\n').map((line, index) => (
                      <p key={index} className="faq-answer">
                        {line}
                      </p>
                    ))}
                </div>
              ))}
            </div>
          ))
        ) : (
          <p className="no-results">No matching questions found.</p>
        )}

        {/* 📩 Contact Support Section */}
        <div className="faq-contact">
          <h2>Still Have Questions?</h2>
          <p>
            If you didn’t find the answer you were looking for, reach out to our
            support team:
          </p>
          <a href="mailto:support@mlondola.com" className="contact-button">
            Contact Support
          </a>
        </div>

        {/* ⬆️ Back to Top Button */}
        {showBackToTop && (
          <button
            className="back-to-top"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <FaArrowUp />
          </button>
        )}
      </div>
    </div>
  );
};

export default Faqs;
