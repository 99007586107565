import React, { useState, useEffect } from 'react';
import { Col, Row, Carousel, Layout } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import {scrollToTop} from '../../utils/helpers'
import SharedFooter from '../../components/shared-footer/shared-footer';

import { links, SLIDES, COUNTRY_NAME } from '../../constants';
import './home.css';

const Link = (props) => {
  const { text, link, icon, onClick } = props;

  return (
    <NavLink
      className={({ isActive, isPending }) =>
        `home-navigation-link${isActive ? ' home-navigation-link-active' : ''}`
      }
      to={link}
      onClick={onClick}
    >
      <img
        className="home-navigation-link_icon"
        src={require(`./../../assets/icons/${icon}.svg`)}
        alt={text}
      />
      {text}
    </NavLink>
  );
};

const Slide = (props) => {
  const { text, title, image } = props;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '100%',
          height: '600px',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          margin: '0 auto 20px auto',
          borderRadius: '15px',
          overflow: 'hidden',
        }}
      />
      <h2 className="home-slide-title">{title}</h2>
      <p className="home-slide-text">{text}</p>
    </div>
  );
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { Footer } = Layout;

  useEffect(() => {
    scrollToTop()
  }, [])

  const handleLinkClick = (link, event) => {
    event.preventDefault();
    navigate(link);
  };

  return (
    <>
      <Col className="home-container">
        <div className="home-logo">
          <a href='/'>
            <img
              src={require('./../../assets/mlondola.png')}
              alt="Mlondola logo"
            />
          </a>
        </div>
        <Row
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}

          className="home-header"
          justify={{ md: 'center', lg: 'space-between'}}
          align="middle"
        >
          <h1 className="home-title">
            <span >Let's explore</span>
            <span className="home-title-accent">{COUNTRY_NAME}</span>
          </h1>
          <nav className="home-navigation">
            {links.map((link) => {
              return (
                <Link
                  key={link.text}
                  text={link.text}
                  link={link.link}
                  icon={link.icon}
                  onClick={(event) => handleLinkClick(link.link, event)}
                />
              );
            })}
          </nav>
        </Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="home-carousel"
        >
          <Carousel dots={false} arrows={true} autoplay>
            {SLIDES.map((slide, i) => {
              return (
                <Slide
                  key={`${i}-slide`}
                  text={slide.text}
                  title={slide.title}
                  image={slide.image}
                />
              );
            })}
          </Carousel>
        </Col>
      </Col>
      <SharedFooter />
    </>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
