import React, { useEffect } from 'react';
import './privacy.css';
import { scrollToTop } from '../../utils/helpers';

const PrivacyPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="mlondola-privacy-page">
      <div className="container privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        {/* <p className="privacy-subtitle">Effective Date: February 2025</p> */}
        <p className="privacy-subtitle last-updated">
          Last Updated: February 2025
        </p>

        <section className="privacy-section">
          <h2>1. Introduction</h2>
          <p>
            Welcome to Mlondola! This Privacy Policy explains how we collect,
            use, and protect your personal information when you use our
            platform.
          </p>
        </section>

        <section className="privacy-section">
          <h2>2. Data We Collect</h2>
          <p>
            When using Mlondola, we may collect the following types of data:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address (if
              provided during sign-up).
            </li>
            <li>
              <strong>Usage Data:</strong> Pages visited, interactions with
              features, time spent on the platform.
            </li>
            <li>
              <strong>Device Information:</strong> Browser type, operating
              system, IP address.
            </li>
            <li>
              <strong>Location Data:</strong> General geographic location (if
              enabled).
            </li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>3. How We Use Your Data</h2>
          <p>We use the collected data to:</p>
          <ul>
            <li>Provide and improve Mlondola’s services.</li>
            <li>Analyze tourism trends and insights.</li>
            <li>Enhance user experience by personalizing features.</li>
            <li>Ensure security and prevent fraudulent activities.</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>4. Data Sharing & Third Parties</h2>
          <p>
            We do not sell your personal data. However, we may share
            non-personal, aggregated analytics with:
          </p>
          <ul>
            <li>
              Tourism stakeholders (e.g., policymakers, businesses) to improve
              services.
            </li>
            <li>Research institutions for data-driven studies.</li>
            <li>Security agencies when required by law.</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>5. Your Rights</h2>
          <p>As a user, you have the right to:</p>
          <ul>
            <li>Request a copy of your personal data.</li>
            <li>Ask us to delete your data.</li>
            <li>Opt-out of data tracking (limited functionality may apply).</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>6. Security Measures</h2>
          <p>
            We take security seriously and use encryption, access control, and
            monitoring to protect your data.
          </p>
        </section>

        <section className="privacy-section">
          <h2>7. Updates to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. If we make
            significant changes, we will notify users through the platform or
            via email (if applicable). Please review this page periodically for
            updates.
          </p>
        </section>

        <section className="privacy-section">
          <h2>8. Contact Us</h2>
          <p>
            If you have any questions regarding this Privacy Policy, contact us
            at:
          </p>
          <p>
            <strong>Email:</strong> privacy@mlondola.com
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
