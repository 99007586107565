import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import { formatFilters } from '../../utils/format-filters.js';

import './bar-chart.css';

const MINIMUM_BAR_HEIGHT = 2;
const Y_AXIS_PADDING = 1.1;

const BarChart = (props) => {
  const { id, data, xTickTotated, height, color, fileName, columns, filters, title } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');

  const [width, setWidth] = useState(0);

  let margin = {top: 20, right: 0, bottom: xTickTotated ? 76 : 28, left: 48},
  svgHeight = (height ||  225) - margin.bottom - margin.top;

  function initChart() {
    const calculatedWidth = document.getElementById(`chart-${id}`).getBoundingClientRect().width - margin.left - margin.right
    const defaultWidth = window.innerWidth <= 768 ? 600 : calculatedWidth
    let initialWidth = defaultWidth,
    svg = d3.select(`#chart-${id}`)
      .append('svg')
        .attr('width', initialWidth + margin.left)
        .attr('height', svgHeight + margin.bottom + margin.top + 15)
        .append('g')
        .attr("transform",
              `translate(${margin.left}, ${margin.top})`);

    svg.append("g")
      .attr("transform", "translate(0," + svgHeight + ")")
      .attr('class', 'x-axis');

    svg.append("g")
      .attr('class', 'y-axis')
      .attr("transform", `translate(0, 0)`);

    setWidth(initialWidth);
  }

  function updateChart() {
    let svg = d3.select(`#chart-${id} svg g`)

    let x = d3.scaleBand()
      .range([ 0, width ])
      .domain(data.map(function(d) { return d.key; }))
      .padding(0.2);
    
    const maxValue = d3.max(data, d => d.value) * Y_AXIS_PADDING;
    const minValue = d3.min(data, d => d.value);

    let y = d3.scaleLinear()
      .domain([0, maxValue])
      .range([svgHeight, 0]);

    function mouseover(e, d) {
      svg.selectAll('rect').attr('fill-opacity', 0.6)
      this.setAttribute('fill-opacity', 1);
      let rects = svg.selectAll('rect').nodes(),
      index = rects.indexOf(this);
      svg.selectAll('.x-axis text').attr('fill-opacity', 0.6)
      svg.selectAll('.x-axis text')
        .filter((d, i) => i === index)
        .attr('fill-opacity', 1);
      this.setAttribute('fill-opacity', 1);
      setTooltipTitle(d.key)
      setTooltipValue(Math.round(d.value))
      setTooltipVisible(true)
    }

    function mousemove(e) {
      setTooltipTop(e.offsetY + margin.top +'px');
      setTooltipLeft(e.offsetX +'px');
    }

    function mouseleave(d) {
      svg.selectAll('rect').attr('fill-opacity', 1);
      svg.selectAll('.x-axis text').attr('fill-opacity', 1);
      setTooltipVisible(false)
    }

    svg.selectAll('.x-axis')
      .transition()
      .duration(1000)
      .call(d3.axisBottom(x));

    if(xTickTotated) {
      svg.selectAll('.x-axis text')
        .attr('transform', 'rotate(-40)')
        .attr('text-anchor', 'end')
        .attr('dx', '-.4em')
        .attr('dy', '.1em')
    }

    // Determine the appropriate tick values based on data range
    const createSmartTicks = () => {
      // Always include 0 as the base value
      const min = 0;
      const max = Math.ceil(maxValue);
      
      // If data only contains 0 and 1 values
      if (max <= 1) {
        return [0, 1];
      }
      
      const range = max - min;
      if (range <= 4) {
        return d3.range(min, max + 1, 1);
      } else {
        const autoTicks = y.ticks(5).map(Math.floor);
        if (!autoTicks.includes(0)) {
          autoTicks.unshift(0);
        }
        return autoTicks;
      }
    };
    
    const tickValues = createSmartTicks();
    svg.selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(
        d3.axisLeft(y)
          .tickValues(tickValues)
          .tickFormat(d3.format('d'))
      );

    svg.selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line').remove();

    svg.selectAll(".tick text").attr('class', 'chart_tick_text');

    svg.selectAll(".mybar")
    .data(data)
    .join("rect")
      .attr('class', 'mybar')
      .attr("fill", color ? color : "#ffffff")
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .transition()
      .duration(1000)
      .attr('rx', () => {
        return x.bandwidth() > 16 ? 8 : x.bandwidth()/2;
      })
      .attr("x", d => {
        return x.bandwidth() > 16 ? (x.bandwidth() - 16)/2 + x(d.key) : x(d.key);
      })
      .attr("y", d => {
        const calculatedHeight = svgHeight - y(d.value);
        return svgHeight - Math.max(calculatedHeight, MINIMUM_BAR_HEIGHT);
      })
      .attr("width", () => {
        return x.bandwidth() > 16 ? 16 : x.bandwidth();
      })
      .attr("height", d => {
        const calculatedHeight = svgHeight - y(d.value);
        return Math.max(calculatedHeight, MINIMUM_BAR_HEIGHT);
      });
      
    svg.selectAll(".value-label").remove();
    if (data.length <= 10) {
      svg.selectAll(".value-label")
        .data(data)
        .join("text")
        .attr("class", "value-label")
        .attr("text-anchor", "middle")
        .attr("x", d => x(d.key) + (x.bandwidth() / 2))
        .attr("y", d => y(d.value) - 5)
        .attr("font-size", "10px")
        .attr("fill", "#666")
        .text(d => Math.round(d.value));
    }
  }

  useEffect(() => {
    const handleResize = () => {
      d3.select(`#chart-${id} svg`).remove();
      initChart();
      updateChart();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width, data]);

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data, width]);

  return (
    <div className='chart-wrapper'>
      <div className='chart-container' id={`chart-${id}`}>
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className='download-btns regions-downloads'>
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="bar-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism'
          }}
        />
      </div>
    </div>
  );
}

BarChart.propTypes = {};

BarChart.defaultProps = {};

export default BarChart;
