import { useState } from 'react';
import './disclaimer-popup.css';

const DisclaimerPopup = () => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    isVisible && (
      <div class="modal-overlay">
        <div class="modal-content">
          <h2 class="modal-title">Disclaimer</h2>
          <p class="modal-text">
            The insights and information provided on this platform are generated
            using AI models and data analytics. While we strive for accuracy, AI
            models are not infallible and may produce errors. We encourage users
            to verify information independently and consult additional sources
            when making decisions. By continuing to use this platform, you
            acknowledge these limitations.
          </p>
          <button onClick={() => setIsVisible(false)} class="modal-button">
            Got it
          </button>
        </div>
      </div>
    )
  );
};

export default DisclaimerPopup;
