import React, { useContext, useState, useEffect, useRef } from 'react';
import { Col, Row, Space } from 'antd';
import Context from '../../context/context';
import Section from '../../components/section/section';
import EstablishmentTypeToggle from '../../components/establishment-type-toggle/establishment-type-toggle';
import ChartCard from '../../components/chart-card/chart-card';
import LineChart from '../../components/line-chart/line-chart';
import BarChart from '../../components/bar-chart/price-bar-chart';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';
import * as d3 from 'd3';
import Map from '../../components/map/map';
import { useLoaderData } from 'react-router-dom';
import './prices.css';
import { scrollToTop } from '../../utils/helpers';
import DownloadAsExcelButton from '../../components/download-as-excel-btn/download-as-excel-btn';
import DownloadAsPNGButton from '../../components/download-png-btn/download-as-png-btn';
import { formatFilters } from '../../utils/format-filters';
import ChartTooltip from '../../components/chart-tooltip/chart-tooltip';

const Prices = () => {
  const {
    filteredEstablishments,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateSelectedRegion,
  } = useContext(Context);

  const geojsonData = useLoaderData();

  const dataRef = useRef();
  dataRef.current = selectedRegion;

  const computePricesOverTime = () => {
    const priceMap = filteredEstablishments.reduce(
      (priceAcc, establishment) => {
        if (
          establishment.price_set[0] &&
          establishment.price_set[0].price_range_avg
        ) {
          const dateKey = establishment.price_set[0].time_of_scrape_iso;
          if (priceAcc[dateKey]) {
            priceAcc[dateKey].push(establishment.price_set[0].price_range_avg);
          } else {
            priceAcc[dateKey] = [establishment.price_set[0].price_range_avg];
          }
        }
        return priceAcc;
      },
      {}
    );

    return Object.keys(priceMap)
      .map((dateKey) => {
        const prices = priceMap[dateKey];
        const averagePrice =
          prices.reduce((acc, price) => acc + price, 0) / prices.length;
        return {
          date: new Date(dateKey),
          value: averagePrice,
        };
      })
      .sort((a, b) => a.date - b.date);
  };

  const computePriceRanges = () => {
    const ranges = { '$0-$50': 0, '$51-$100': 0, '$101-$150': 0, '$151+': 0 };
    filteredEstablishments.forEach((establishment) => {
      const price = establishment.price_set?.[0]?.price_range_avg;
      if (price === undefined || price === null) return; // Skip establishments without a valid price
      if (price <= 50) ranges['$0-$50'] += 1;
      else if (price <= 100) ranges['$51-$100'] += 1;
      else if (price <= 150) ranges['$101-$150'] += 1;
      else ranges['$151+'] += 1;
    });
    return Object.entries(ranges).map(([range, count]) => ({ range, count }));
  };

  const computeAveragePriceBySubcategory = () => {
    const groupedData = d3.group(filteredEstablishments, (d) => d.subcategory);

    return Array.from(groupedData, ([key, values]) => ({
      key,
      value: d3.mean(
        values.flatMap((d) =>
          d.price_set
            .filter((p) => p.price_range_avg != null)
            .map((p) => p.price_range_avg)
        )
      ),
    })).filter((item) => item.value !== undefined);
  };

  function groupPricesByRankingRangeForSubcategory(data) {
    const result = {};

    // Define ranking ranges based on a 0-5 scale
    const ranges = [
      [0, 2.5], // Low-rated
      [2.5, 3.5], // Moderate-Low
      [3.5, 4.2], // Moderate-High
      [4.2, 4.8], // High,
      [4.8, 5.0], // Very High
    ];

    data.forEach((listing) => {
      let { subcategory, rating, price_set, data_platform } = listing;

      // Normalize Booking.com ratings (convert 0-10 scale to 0-5)
      if (data_platform === 'Booking') {
        rating = rating / 2;
      }

      // Get the most recent price
      if (!price_set || price_set.length === 0) return;
      const latestPrice = price_set[0].price_range_avg;
      if (latestPrice === null) return;

      // Initialize subcategory if it doesn't exist
      if (!result[subcategory]) {
        result[subcategory] = {};
        ranges.forEach(([start, end]) => {
          result[subcategory][`${start}-${end}`] = {
            prices: [],
            count: 0,
            avg_price: 0,
          };
        });
      }

      // Find appropriate range and add price
      ranges.forEach(([start, end]) => {
        if (rating >= start && rating <= end) {
          const rangeKey = `${start}-${end}`;
          result[subcategory][rangeKey].prices.push(latestPrice);
          result[subcategory][rangeKey].count++;
          result[subcategory][rangeKey].avg_price = Number(
            (
              result[subcategory][rangeKey].prices.reduce((a, b) => a + b, 0) /
              result[subcategory][rangeKey].count
            ).toFixed(2)
          );
        }
      });
    });

    // Convert result to array format
    const formattedResult = Object.keys(result).map((group) => {
      const groupData = { group };

      // Add average price for each range
      ranges.forEach(([start, end]) => {
        const rangeKey = `${start}-${end}`;
        groupData[rangeKey] = result[group][rangeKey].avg_price;
      });

      return groupData;
    });

    return formattedResult;
  }

  const [geodata, setGeodata] = useState(null);

  useEffect(() => {
    scrollToTop();

    // Fetch GeoJSON file from the public folder
    const fetchGeoData = async () => {
      try {
        const response = await fetch('/geodata.geojson'); // Static file path
        if (!response.ok) {
          throw new Error('Failed to load GeoJSON');
        }
        const json = await response.json();
        setGeodata(json);
      } catch (error) {
        console.error('Error fetching GeoJSON:', error);
      }
    };

    fetchGeoData();
  }, []);

  function groupPricesByState(data) {
    const statePrices = {};

    data.forEach((item) => {
      const state = item.state;
      if (!state) return;
      const prices = item.price_set
        .filter((p) => p.price_range_avg != null) // Filter valid price entries
        .map((p) => p.price_range_avg); // Extract average prices

      if (!statePrices[state]) {
        statePrices[state] = [];
      }

      statePrices[state].push(...prices); // Collect all prices for the state
    });

    // Calculate average price for each state
    const result = Object.entries(statePrices).map(([state, prices]) => ({
      state,
      averagePrice: prices.length
        ? prices.reduce((sum, p) => sum + p, 0) / prices.length
        : null,
    }));

    return result;
  }

  function mapRegionClick(e, d) {
    if (dataRef.current.includes(d.properties.shapeName)) {
      updateSelectedRegion(
        dataRef.current.filter((region) => region !== d.properties.shapeName)
      );
    } else {
      updateSelectedRegion([...dataRef.current, d.properties.shapeName]);
    }
  }

  const [prices, setPrices] = useState([]);
  const [pricesBySubcategory, setPricesBySubcategory] = useState([]);
  const [priceRanges, setPriceRanges] = useState([]);
  const [rankingRangePrices, setRankingRangePrices] = useState([]);
  const [pricesByState, setPricesByState] = useState([]);

  useEffect(() => {
    setPrices(computePricesOverTime());
    setPricesBySubcategory(computeAveragePriceBySubcategory());
    setPriceRanges(computePriceRanges());
    setRankingRangePrices(
      groupPricesByRankingRangeForSubcategory(filteredEstablishments)
    );
    setPricesByState(groupPricesByState(filteredEstablishments));
  }, [filteredEstablishments]);

  function xTicksFormatter(value) {
    return `${value.toLocaleString('default', {
      month: 'long',
    })} ${value.getFullYear()}`;
  }

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  const filteredPricesBySubcategory = pricesBySubcategory.filter(
    (item) => item.value !== undefined
  );

  const getValueFunction = (stateData) => {
    return stateData ? stateData.averagePrice : 0; // Return 0 instead of null for consistency
  };

  // Get color variations based on price ranges
  const getColorsFunction = (data) => {
    if (!data || Object.keys(data).length === 0) {
      return {
        trashold: [],
        range: d3.schemeBlues[7],
      };
    }

    // Extract and sort prices
    const prices = Object.values(data)
      .map((item) => item.averagePrice)
      .filter((price) => price != null)
      .sort((a, b) => a - b);

    if (prices.length === 0) {
      return {
        trashold: [],
        range: d3.schemeBlues[7],
      };
    }

    const minPrice = prices[0];
    const maxPrice = prices[prices.length - 1];

    // Calculate step size for more granular thresholds
    const step = Math.floor((maxPrice - minPrice) / 10);

    // Create threshold array with more intervals
    const thresholdArray = [minPrice];
    for (let i = 1; i < 7; i++) {
      thresholdArray.push(minPrice + step * i);
    }

    return {
      trashold: thresholdArray,
      range: d3.schemeBlues[7],
    };
  };

  const getTooltipTextFunction = (stateData) => {
    if (!stateData || stateData.averagePrice == null) {
      return 'No data available';
    }
    return `$${stateData.averagePrice.toFixed(2)}`;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Price Trends" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px', marginBottom: '8px' }}>
        <Col span={24}>
          <EstablishmentTypeToggle />
        </Col>
      </Row>
      <Col gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Row gutter={[24, 24]} className="top-section">
          <Col md={{ span: 10 }}>
            <Section title={<span>Average Price By Region</span>}>
              {/* <ChartCard> */}
              <Map
                id="malawi-price-map"
                data={pricesByState.reduce((acc, item) => {
                  acc[item.state] = item;
                  return acc;
                }, {})}
                click={(e, d) => {
                  mapRegionClick(e, d);
                }}
                geodata={geojsonData}
                getValueFunction={getValueFunction}
                getColorsFunction={getColorsFunction}
                getTooltipTextFunction={getTooltipTextFunction}
                fileName="malawi-price-map"
                filters={appliedFilters}
                title="Average Price By Region"
                columns={{ state: 'Region', averagePrice: 'Average Price' }}
                mapHeight={400}
              />
              {/* </ChartCard> */}
            </Section>
          </Col>
          <Col md={{ span: 14 }}>
            <Section
              title={<span>Number of Establishments by Price Range</span>}
            >
              <ChartCard>
                <PriceRangeBarChart
                  data={priceRanges}
                  id="price-range-chart"
                  fileName={'price-range-chart'}
                  filters={appliedFilters}
                  // title="Number of Establishments by Price Range"
                />
              </ChartCard>
            </Section>
          </Col>
        </Row>
        <Col span={24}>
          <Section title={<span>Average price by subcategory</span>}>
            <ChartCard>
              <BarChart
                id="average-price-by-subcategory"
                data={filteredPricesBySubcategory}
                xTickTotated={true}
                height={300}
                columns={{ key: 'Subcategory', value: 'Average Price' }}
                fileName="average-price-by-subcategory"
                filters={appliedFilters}
                title={'Average Price by Subcategory'}
              />
            </ChartCard>
          </Section>
        </Col>

        <Col span={24}>
          <Section title={<span>Ranking Range Prices</span>}>
            <ChartCard>
              {/* <AccommodationRankingChart
                data={groupPricesByRankingRangeForSubcategory(
                  filteredEstablishments
                )}
                id="ranking-range-prices"
              /> */}
              <GroupedBarChart
                id="ranking-range-prices"
                data={rankingRangePrices}
                xTickTotated={true}
                columns={{
                  group: 'Subcategory',
                  '0-2.5': '0 - 2.5',
                  '2.5-3.5': '2.5 - 3.5',
                  '3.5-4.2': '3.5 - 4.2',
                  '4.2-4.8': '4.2 - 4.8',
                  '4.8-5': '4.8 - 5',
                }}
                fileName="ranking-range-prices"
                filters={appliedFilters}
                title={'Ranking Range Prices'}
              />
            </ChartCard>
          </Section>
        </Col>
      </Col>
    </>
  );
};

export default Prices;

const PriceRangeBarChart = ({ data, id, title, fileName, filters }) => {
  const chartRef = useRef(null);
  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');

  const [width, setWidth] = useState(0);

  const margin = { top: 20, right: 30, bottom: 50, left: 60 };
  const svgHeight = 400 - margin.top - margin.bottom;

  // Ensure we have valid data
  const isDataEmpty = data.length === 0 || data.every((d) => d.count === 0);

  useEffect(() => {
    if (!isDataEmpty) {
      initChart();
    }

    return () => {
      // Cleanup the chart before unmounting or re-rendering
      d3.select(chartRef.current).select('svg').remove();
    };
  }, [data]);

  function initChart() {
    const chartElement = document.getElementById(`chart-${id}`);
    if (!chartRef.current) return;

    const initialWidth =
      chartRef.current.getBoundingClientRect().width -
      margin.left -
      margin.right;
    setWidth(initialWidth);

    d3.select(chartRef.current).select('svg').remove();

    let svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', initialWidth + margin.left + margin.right)
      .attr('height', svgHeight + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    svg
      .append('g')
      .attr('transform', `translate(0, ${svgHeight})`)
      .attr('class', 'x-axis')
      .style('font-size', '13px');

    svg.append('g').attr('class', 'y-axis').style('font-size', '13px');
  }

  function updateChart() {
    if (!data || data.length === 0) return;

    let svg = d3.select(`#chart-${id} svg g`);

    if (svg.empty()) return;

    let x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.range))
      .padding(0.4);

    let y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .nice()
      .range([svgHeight, 0]);

    function mouseover(event, d) {
      svg.selectAll('rect').attr('fill-opacity', 0.6);
      event.target.setAttribute('fill-opacity', 1);

      setTooltipTitle(`Range: ${d.range}`);
      setTooltipValue(`Establishments: ${d.count}`);
      setTooltipVisible(true);
    }

    function mousemove(event) {
      setTooltipTop(event.offsetY + margin.top + 'px');
      setTooltipLeft(event.offsetX + 'px');
    }

    function mouseleave() {
      svg.selectAll('rect').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    svg.selectAll('.x-axis').transition().duration(1000).call(d3.axisBottom(x));
    svg
      .selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(
        d3
          .axisLeft(y)
          .ticks(
            Math.min(
              5,
              d3.max(data, (d) => d.count)
            )
          )
          .tickFormat(d3.format('d'))
      );

    svg
      .selectAll('.mybar')
      .data(data)
      .join('rect')
      .attr('class', 'mybar')
      .attr('fill', 'white')
      .attr('rx', '14')
      .attr('ry', '14')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .transition()
      .duration(1000)
      .attr('x', (d) => x(d.range))
      .attr('y', (d) => y(d.count))
      .attr('width', x.bandwidth())
      .attr('height', (d) => svgHeight - y(d.count));
  }

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data]);

  const columns = { range: 'Price Range', count: 'Number of Establishments' };

  return (
    <div className="chart-wrapper">
      <div
        ref={chartRef}
        className="chart-container"
        id={`chart-${id}`}
        style={{ height: 'fit-content' }}
      >
        {isDataEmpty ? (
          <p
            style={{
              textAlign: 'center',
              marginTop: '20px',
              color: '#EB5757',
            }}
          >
            No data available for the selected filters.
          </p>
        ) : null}
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>

      <div className="download-btns">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="price-range-chart.png"
          metadata={{
            title,
            filters: formatFilters({ filters }),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

// Ranking Range Prices Stacked Bar Chart

const AccommodationRankingChart = ({ data, id }) => {
  const [tooltip, setTooltip] = useState({
    visible: false,
    title: '',
    value: '',
    top: 0,
    left: 0,
  });
  const [width, setWidth] = useState(0);

  const margin = { top: 20, right: 30, bottom: 50, left: 200 };
  const svgHeight = 400 - margin.top - margin.bottom;

  function initChart() {
    const chartElement = document.getElementById(`chart-${id}`);
    const initialWidth = chartElement
      ? chartElement.getBoundingClientRect().width - margin.left - margin.right
      : 600;
    setWidth(initialWidth);

    let container = d3.select(`#chart-${id}`);
    container.select('svg').remove();

    let svg = container
      .append('svg')
      .attr('width', initialWidth + margin.left)
      .attr('height', svgHeight + margin.bottom + margin.top)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    svg
      .append('g')
      .attr('transform', `translate(0, ${svgHeight})`)
      .attr('class', 'x-axis');
    svg.append('g').attr('class', 'y-axis');
  }

  function updateChart() {
    let svg = d3.select(`#chart-${id} svg g`);

    // Ensure data is correctly processed
    const processedData = data.map((item) => ({
      subcategory: item.subcategory,
      low: item.rankingRanges.low ?? 0,
      medium: item.rankingRanges.medium ?? 0,
      high: item.rankingRanges.high ?? 0,
      formattedRanges: item.formattedRanges || {}, // Ensure it's always an object
      itemsWithRankings: item.itemsWithRankings,
    }));

    const color = d3
      .scaleOrdinal()
      .domain(['low', 'medium', 'high'])
      .range(['#8884d8', '#82ca9d', '#ffc658']);

    const y = d3
      .scaleBand()
      .domain(processedData.map((d) => d.subcategory))
      .range([0, svgHeight])
      .padding(0.1);

    const x = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(processedData, (d) => Math.max(d.low, d.medium, d.high)),
      ])
      .nice()
      .range([0, width]);

    const subgroups = ['low', 'medium', 'high'];
    const groupSpacing = y.bandwidth() / subgroups.length;

    function mouseover(event, d) {
      const key = d3.select(event.target).attr('data-key');

      // Ensure formattedRanges[key] exists before using it
      const priceDisplay = d.formattedRanges?.[key] ?? 'N/A';

      setTooltip({
        title: `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } Range: ${priceDisplay}`,
        value: `Items with Rankings: ${d.itemsWithRankings}`,
        visible: true,
        top: event.pageY + 'px',
        left: event.pageX + 'px',
      });
    }

    function mouseleave() {
      setTooltip((prev) => ({ ...prev, visible: false }));
    }

    svg.selectAll('.x-axis').transition().duration(1000).call(d3.axisBottom(x));
    svg.selectAll('.y-axis').transition().duration(1000).call(d3.axisLeft(y));

    const bars = svg
      .selectAll('.bar-group')
      .data(processedData)
      .join('g')
      .attr('class', 'bar-group');

    subgroups.forEach((key, i) => {
      bars
        .append('rect')
        .attr('data-key', key)
        .attr('y', (d) => y(d.subcategory) + i * groupSpacing)
        .attr('x', 0)
        .attr('height', groupSpacing * 0.8)
        .attr('width', (d) => x(d[key]))
        .attr('fill', color(key))
        .on('mouseover', mouseover)
        .on('mouseout', mouseleave);
    });
  }

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data]);

  return (
    <div className="chart-wrapper">
      <div className="chart-container" id={`chart-${id}`}>
        {tooltip.visible && (
          <div
            className="tooltip"
            style={{
              position: 'absolute',
              top: tooltip.top,
              left: tooltip.left,
              background: '#333',
              color: '#fff',
              padding: '5px 10px',
              borderRadius: '4px',
            }}
          >
            <strong>{tooltip.title}</strong>
            <br />
            {tooltip.value}
          </div>
        )}
      </div>
    </div>
  );
};

const GroupedBarChart = ({
  id,
  data,
  xTickTotated,
  height,
  color,
  colorScale: customColorScale,
  fileName,
  columns,
  filters,
  title,
}) => {
  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [width, setWidth] = useState(0);

  let margin = { top: 12, right: 0, bottom: xTickTotated ? 172 : 28, left: 64 };
  let svgHeight = (height || 200) - margin.top;

  const defaultColorScale = d3
    .scaleOrdinal()
    .domain(['0-2.5', '2.5-3.5', '3.5-4.2', '4.2-4.8', '4.8-5'])
    .range(['#EB5757', '#E28846', '#F2C94C', '#6FCF97', '#297c4c']);

  const colorScale = customColorScale || defaultColorScale;

  // Check if any valid price data exists
  const hasValidPrices = data.some((entry) =>
    Object.values(entry).some((val) => typeof val === 'number' && val !== null)
  );

  function initChart() {
    let initialWidth =
      document.getElementById(`chart-${id}`).getBoundingClientRect().width -
      margin.left -
      margin.right;

    const legendContainer = d3
      .select(`#chart-${id}`)
      .append('div')
      .attr('class', 'legend-container')
      .style('display', 'flex')
      .style('flex-direction', 'row')
      .style('gap', '20px')
      .style('margin-top', '5px')
      .style('justify-content', 'center');

    // Populate the legend
    colorScale.domain().forEach((d) => {
      const legendItem = legendContainer
        .append('div')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('margin-bottom', '5px'); // Space between legend items

      legendItem
        .append('div')
        .style('width', '10px')
        .style('height', '10px')
        .style('background-color', colorScale(d))
        .style('margin-right', '5px'); // Space between box and text

      legendItem
        .append('span')
        .text(d)
        .style('font-size', '12px')
        .style('color', '#fff');
    });

    setWidth(initialWidth);
  }

  function updateChart() {
    if (!data || !hasValidPrices) return;
    let svg = d3.select(`#chart-${id} svg g`);
    const columns = [
      'group',
      '0-2.5',
      '2.5-3.5',
      '3.5-4.2',
      '4.2-4.8',
      '4.8-5',
    ];
    const subgroups = columns.slice(1);
    const groups = data.map((d) => d.group);
    let totalWidth = Math.max(width, groups.length * 80);

    const x = d3.scaleBand().domain(groups).range([0, totalWidth]).padding(0.2);
    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) =>
          d3.max(Object.values(d).filter((v) => typeof v === 'number'))
        ),
      ])
      .range([svgHeight, 0]);

    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding(0.2);

    function mouseover(e, d) {
      svg.selectAll('.bars rect').attr('fill-opacity', 0.6);
      this.setAttribute('fill-opacity', 1);
      setTooltipTitle(`${d.group} - ${d.key}`);
      setTooltipValue(`$${d.value.toFixed(2)}`);
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(`${e.offsetY + margin.top}px`);
      setTooltipLeft(`${e.offsetX}px`);
    }

    function mouseleave() {
      svg.selectAll('.bars rect').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    d3.select(`#chart-${id} svg`)
      .attr('width', totalWidth + margin.left + margin.right)
      .attr('height', svgHeight + margin.bottom + margin.top);

    svg.select('.x-axis').transition().duration(1000).call(d3.axisBottom(x));
    if (xTickTotated) {
      svg
        .selectAll('.x-axis text')
        .attr('transform', 'rotate(-40)')
        .attr('text-anchor', 'end')
        .attr('dx', '-.4em')
        .attr('dy', '.1em');
    }

    svg
      .select('.y-axis')
      .transition()
      .duration(1000)
      .call(d3.axisLeft(y).ticks(5));
    svg
      .selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line')
      .remove();
    svg.selectAll('.tick text').attr('class', 'chart_tick_text');

    svg
      .selectAll('.bars')
      .data(data)
      .join('g')
      .attr('class', 'bars')
      .attr('transform', (d) => `translate(${x(d.group)}, 0)`)
      .selectAll('rect')
      .data((d) =>
        subgroups.map((key) => ({
          key: key,
          value: d[key],
          group: d.group,
        }))
      )
      .join('rect')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .attr('x', (d) => xSubgroup(d.key))
      .attr('width', xSubgroup.bandwidth())
      .attr('rx', 6)
      .transition()
      .duration(1000)
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => svgHeight - y(d.value))
      .attr('fill', (d) => colorScale(d.key));
  }

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data]);

  return (
    <div className="chart-wrapper">
      <div
        className="chart-container"
        id={`chart-${id}`}
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
      >
        {hasValidPrices ? (
          <svg width="100%" height={svgHeight + margin.top + margin.bottom}>
            <g transform={`translate(${margin.left}, ${margin.top})`}>
              <g
                className="x-axis"
                transform={`translate(0, ${svgHeight})`}
              ></g>
              <g className="y-axis"></g>
            </g>
          </svg>
        ) : (
          <p
            style={{ textAlign: 'center', color: '#EB5757', marginTop: '20px' }}
          >
            No data available for the selected filters.
          </p>
        )}
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className="download-btns">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="grouped-bar-chart.png"
          metadata={{
            title,
            filters: formatFilters({ filters }),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};
