import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Input, Modal, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Section from '../section/section';
import ChartCard from '../chart-card/chart-card';
import BarChart from '../bar-chart/bar-chart';
import Map from '../map/map';
import GenderChart from '../gender-chart/gender-chart';
import Context from '../../context/context';
import { Alert } from '../Alert/alert';
import * as d3 from 'd3';

import './seasons-regions-gender-stats.css';

import geojsonData from '../../assets/countries.geo.json';

const SEASON_COLORS = {
  W: { season: 'Winter', color: '#76c7c0' },
  Sp: { season: 'Spring', color: '#7bc043' },
  Su: { season: 'Summer', color: '#f4a261' },
  A: { season: 'Autumn', color: '#e76f51' },
};

const SeasonsCounter = (props) => {
  const { value, text, color } = props;
  return (
    <div className="seasons-counter">
      <span
        className="seasons-counter-text"
        style={{ borderTop: `5px solid ${color}` }}
      >
        {text}
      </span>
      <span className="seasons-counter-value">{value}</span>
    </div>
  );
};

const SeasonsRegionsGenderStats = (props) => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  function computeSeasonVisits() {
    const seasonKeyList = [];
    const seasonVisitMap = {};

    const datelist = [...filteredReviews].sort((a, b) => a.date - b.date);

    datelist.forEach((review) => {
      const date = new Date(review.date);
      const month = date.getMonth();
      let season;

      if (month < 2 || month === 11) {
        season = 'W';
      } else if (month < 5) {
        season = 'Sp';
      } else if (month < 8) {
        season = 'Su';
      } else {
        season = 'A';
      }

      let year = `${date.getFullYear() % 100}`;
      let seasonKey = `${season} ${year}`;

      if (season === 'W') {
        if (month < 2) {
          seasonKey = `${season} ${year}`;
        } else {
          seasonKey = `${season} ${(date.getFullYear() % 100) - 1}`;
        }
      }

      if (!seasonKeyList.includes(seasonKey)) {
        seasonKeyList.push(seasonKey);
      }
      seasonVisitMap[seasonKey] = (seasonVisitMap[seasonKey] || 0) + 1;
    });

    return seasonKeyList
      .sort((a, b) => {
        const [seasonA, yearA] = a.split(' ');
        const [seasonB, yearB] = b.split(' ');

        const yearComparison = parseInt(yearA) - parseInt(yearB);
        if (yearComparison !== 0) {
          return yearComparison;
        }

        const seasonOrder = { W: 0, Sp: 1, Su: 2, A: 3 };
        return seasonOrder[seasonA] - seasonOrder[seasonB];
      })
      .map((seasonKey) => ({
        key: seasonKey,
        value: seasonVisitMap[seasonKey],
        color: SEASON_COLORS[seasonKey.split(' ')[0]].color,
      }));
  }

  function computeRegionVisits() {
    const regionMap = filteredReviews
      .filter((r) => r.location)
      .reduce((reviewAcc, review) => {
        if (reviewAcc[review.location]) {
          reviewAcc[review.location] += 1;
        } else {
          reviewAcc[review.location] = 1;
        }
        return reviewAcc;
      }, {});
    return regionMap;
  }

  function computeVisitorGenders() {
    return filteredReviews
      .filter((r) => r.reviewer_gender_prediction !== 'Unknown')
      .reduce((reviewAcc, review) => {
        // Map 'Male' to 'Men' and 'Female' to 'Women'
        const gender =
          review.reviewer_gender_prediction === 'Male'
            ? 'Men'
            : review.reviewer_gender_prediction === 'Female'
            ? 'Women'
            : review.reviewer_gender_prediction;

        if (reviewAcc[gender]) {
          reviewAcc[gender] += 1;
        } else {
          reviewAcc[gender] = 1;
        }
        return reviewAcc;
      }, {});
  }

  function getValueFunction(item) {
    return item ? item : 0;
  }

  function getColorsFunction(data) {
    const valuesArray = Object.values(data).sort((a, b) => a - b);
    const step = Math.floor(
      (valuesArray[valuesArray.length - 1] - valuesArray[0]) / 10
    );
    const trasholdArray = [valuesArray[0]];
    for (let i = 1; i < 5; i++) {
      trasholdArray.push(trasholdArray[i - 1] + step * i);
    }
    return {
      trashold: trasholdArray,
      range: d3.schemeBlues[5],
    };
  }

  function getTooltipTextFunction(item) {
    if (!item) return 0;
    return item;
  }

  function computeSeasonSum() {
    const seasonSumInit = {
      Winter: 0,
      Spring: 0,
      Summer: 0,
      Autumn: 0,
    };

    filteredReviews.forEach((review) => {
      const date = new Date(review.date);
      const month = date.getMonth();
      if (month < 2 || month === 11) {
        seasonSumInit['Winter'] += 1;
      } else if (month < 5) {
        seasonSumInit['Spring'] += 1;
      } else if (month < 8) {
        seasonSumInit['Summer'] += 1;
      } else {
        seasonSumInit['Autumn'] += 1;
      }
    });
    return seasonSumInit;
  }

  const [seazonVisits, seSeazonVisits] = useState(computeSeasonVisits());
  const [regionVisits, setRegionVisits] = useState(computeRegionVisits());
  const [visitorsGender, setVisitorsGender] = useState(computeVisitorGenders());
  const [regionSearch, setRegionsSearch] = useState('');
  const [regionsFilteredWithSearch, setRegionsFilteredWithSearch] = useState(
    []
  );

  const [seasonSum, setSeasonSum] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setRegionVisits(computeRegionVisits());
    setVisitorsGender(computeVisitorGenders());
    seSeazonVisits(computeSeasonVisits());
    setSeasonSum(computeSeasonSum());
  }, [filteredReviews]);

  useEffect(() => {
    const filteredRegions = Object.entries(regionVisits)
      .filter(([key, value]) => {
        return key.toLowerCase().includes(regionSearch.toLowerCase());
      })
      .sort((a, b) => b[1] - a[1])
      .slice(0, 7);
    setRegionsFilteredWithSearch(filteredRegions);
  }, [regionSearch, regionVisits]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Row gutter={[24, 24]} className="visitors-container">
        <Col span={14}>
          <Section title={<span>Seasonal Reviewers Trends </span>}>
            <ChartCard>
              <BarChart
                id="seasons"
                data={seazonVisits}
                hasLegend={false}
                xTickTotated={true}
                columns={{ key: 'Season', value: 'Number of Reviews' }}
                fileName="reviews-over-seasons"
                filters={appliedFilters}
                title="Number of reviews based on seasons"
                color={(d) => d.color}
              />
            </ChartCard>
          </Section>
          <div className="season-counters-container">
            {Object.entries(seasonSum).map(([key, value]) => {
              const color = Object.values(SEASON_COLORS).find(
                (s) => s.season === key
              ).color;
              return (
                <SeasonsCounter
                  key={key}
                  value={value}
                  text={key}
                  color={color}
                />
              );
            })}
          </div>
        </Col>
        <Col span={10}>
          <Section
            title={<span>Reviewers Gender Distribution (Men/Women)</span>}
            className={'visitor-gender-distribution'}
          >
            <ChartCard>
              <GenderChart
                id="gender"
                height={240}
                legendRight={true}
                data={visitorsGender}
                hasLegend={true}
                filters={appliedFilters}
                title="Reviewers gender"
              />
            </ChartCard>
          </Section>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Section
            title={
              <div className="vistors-title-row">
                <span>Reviewers by Country</span>
                {selectedPlatform !== 'Airbnb' && (
                  <Button onClick={showModal} className="reviewers-countries">
                    See Reviewers Countries
                  </Button>
                )}
              </div>
            }
          >
            <Col
              gutter={[24, 24]}
              className="visitors-by-country map-container"
            >
              <Col span={24}>
                <Map
                  id="world"
                  height={300}
                  data={regionVisits}
                  geodata={geojsonData}
                  getValueFunction={getValueFunction}
                  getColorsFunction={getColorsFunction}
                  getTooltipTextFunction={getTooltipTextFunction}
                  columns={{ key: 'Number of Reviews' }}
                  fileName="reviews-based-on-origin"
                  filters={appliedFilters}
                  title="Number of reviews based on origin of the visitor"
                />
                {selectedPlatform === 'Airbnb' && (
                  <div className="map-overlay dark">
                    <Alert variant="warning" className="dark-theme-alert">
                      <h5 className="alert-title">
                        No Location Data Available
                      </h5>
                      <div className="alert-description">
                        Location data is not available for Airbnb reviews &nbsp;
                        Please Choose another platform
                      </div>
                    </Alert>
                  </div>
                )}
              </Col>
            </Col>
          </Section>
        </Col>
      </Row>
      <Modal open={isModalOpen} onCancel={handleOk}>
        <Col span={24}>
          <ChartCard>
            <div>
              <Input
                className="search-input-region"
                variant="outlined"
                prefix={<SearchOutlined style={{ color: '#fff' }} />}
                value={regionSearch}
                onChange={(e) => {
                  setRegionsSearch(e.target.value);
                }}
              />
            </div>
            {regionsFilteredWithSearch.map(([key, value], i) => {
              return (
                <div className="country-list-item" key={key}>
                  <span className="country-list-item-index">{i + 1}</span>
                  <span className="country-list-item-values">
                    <span className="country-list-item-key">{key}</span>
                    <span className="country-list-item-value">{value}</span>
                  </span>
                </div>
              );
            })}
          </ChartCard>
        </Col>
      </Modal>
    </>
  );
};

SeasonsRegionsGenderStats.propTypes = {};

SeasonsRegionsGenderStats.defaultProps = {};

export default SeasonsRegionsGenderStats;
